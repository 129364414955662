var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
// Note: dotenv-webpack will replace process.env.XXXX with XXXX defined in .env
// but it won't work for process.env[key] as process.env won't be generated in browser environment
import { getDomainName, parseURLParams } from '@shm-open/utilities';
import { App } from '@shm/interface/lib/common/app';
import { browser } from '@shm/interface/lib/web';
const urlParams = parseURLParams(window.location.href);
/**
 * 返回当前运行环境的App枚举
 */
const getApp = () => {
    // check about query first
    const queryApp = Array.isArray(urlParams.app) ? urlParams.app[0] : urlParams.app;
    let app = Number(queryApp);
    if (!app || Number.isNaN(app)) {
        // infer app type
        if (browser.isWechat()) {
            app = App.APP_SHIHUIMIAO_WECHAT_H5;
        }
        else if (browser.isQutoutiaoApp()) {
            app = App.APP_QUTOUTIAO;
        }
        else {
            // try to infer it from current running browser info
            switch (getDomainName(window.location.hostname)) {
                // case 'miaoqianguan.com':
                //     app = App.APP_MIAOQIANGUAN;
                //     break;
                // // TODO: check final domain name
                // case 'shmer.cn': // temp
                // case 'mtehui.cn':
                // case 'mtehui.net':
                //     app = App.APP_MIAOTEHUI;
                //     break;
                // case 'wondershm.com': // temp
                // case 'miaofuli.cn':
                //     app = App.APP_MIAOFULI;
                //     break;
                // case 'myouhui.cn':
                // case 'myouhui.net':
                // case 'shihuilife.cn':
                // case 'shihuilife.net':
                // case 'shihuimiao.net':
                //     app = App.APP_MIAOYOUHUI;
                //     break;
                // case 'miaoxiaobao.cn':
                // case 'yxer.net':
                // case 'zhuntianqi.cn':
                //     app = App.APP_MIAOXIAOBAO;
                //     break;
                // case 'yaobijilai.com':
                //     app = App.APP_PREPARE_01;
                //     break;
                default:
                    // default to shihuimiao app (no matter it's in browser or app)
                    app = App.APP_SHIHUIMIAO;
                    break;
            }
        }
    }
    return app;
};
const app = getApp();
export const selectByApp = (shm, options) => {
    if (options[app] !== undefined) {
        return options[app];
    }
    return shm;
};
export const config = {
    // 发布控制(这里为空即可)
    RELEASE_CONTROL: {
    // TODO: read release control env variable here
    },
    /**
     * 是否是开发环境
     */
    IS_LOCAL_DEV: Number(window.location.port) > 80,
    /**
     * 当前的URL参数
     */
    URL_PARAMS: urlParams,
    /**
     * 当前运行的App环境
     */
    APP: app,
    /**
     * SHM App马甲包
     */
    SHM_APP_FAMILY: [
        App.APP_SHIHUIMIAO,
        App.APP_MIAOQIANGUAN,
        App.APP_MIAOTEHUI,
        App.APP_MIAOFULI,
        App.APP_MIAOYOUHUI,
        App.APP_MIAOXIAOBAO,
        App.APP_PREPARE_01,
    ],
    /**
     * App显示名称
     */
    APP_NAME: selectByApp('实惠喵', {
        [App.APP_MIAOQIANGUAN]: '喵钱罐',
        [App.APP_MIAOTEHUI]: '喵特惠',
        [App.APP_MIAOFULI]: '喵福利',
        [App.APP_MIAOYOUHUI]: '喵优惠',
        [App.APP_MIAOXIAOBAO]: '喵小报',
        [App.APP_PREPARE_01]: '即来优惠',
    }),
    /**
     * 所有App 名称列表
     */
    APP_NAMES: {
        [App.APP_SHIHUIMIAO]: '实惠喵',
        [App.APP_MIAOQIANGUAN]: '喵钱罐',
        [App.APP_MIAOTEHUI]: '喵特惠',
        [App.APP_MIAOFULI]: '喵福利',
        [App.APP_MIAOYOUHUI]: '喵优惠',
        [App.APP_MIAOXIAOBAO]: '喵小报',
        [App.APP_PREPARE_01]: '即来优惠',
    },
    /**
     *  马甲包下载地址前缀，文件名需要动态拼
     */
    APP_DOWNLOAD_URL_PREFIX: {
        [App.APP_SHIHUIMIAO]: 'https://download.miaoqianguan.com/latest-release/shm/',
        [App.APP_MIAOQIANGUAN]: 'https://download.miaoqianguan.com/latest-release/miaoqianguan/',
        [App.APP_MIAOTEHUI]: 'https://download.mtehui.cn/latest-release/mtehui/',
        [App.APP_MIAOFULI]: 'https://download.miaofuli.cn/latest-release/miaofuli/',
        [App.APP_MIAOYOUHUI]: 'https://download.myouhui.cn/latest-release/miaoyouhui/',
        [App.APP_MIAOXIAOBAO]: 'https://download.miaoxiaobao.cn/latest-release/miaoxiaobao/',
        [App.APP_PREPARE_01]: 'https://download.miaoxiaobao.cn/latest-release/miaoxiaobao/',
    },
    /**
     *  静态资源地址前缀，文件名需要动态拼
     */
    APP_ASSETS_URL_PREFIX: {
        [App.APP_SHIHUIMIAO]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/shm/',
        [App.APP_MIAOQIANGUAN]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/miaoqianguan/',
        [App.APP_MIAOTEHUI]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/mtehui/',
        [App.APP_MIAOFULI]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/miaofuli/',
        [App.APP_MIAOYOUHUI]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/miaoyouhui/',
        [App.APP_MIAOXIAOBAO]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/miaoxiaobao/',
        [App.APP_PREPARE_01]: 'https://shm-internal.oss-cn-beijing.aliyuncs.com/assets/miaoxiaobao/',
    },
    // 应用包名
    PACKAGE_NAME: selectByApp('com.xihu.shihuimiao', {
        [App.APP_MIAOQIANGUAN]: 'com.xiangchen.miaoqianguan',
        [App.APP_MIAOTEHUI]: 'com.moshuang.miaotehui',
        [App.APP_MIAOFULI]: 'com.moshuang.miaofuli',
        [App.APP_MIAOYOUHUI]: 'com.xiangchen.miaoyouhui',
        [App.APP_MIAOXIAOBAO]: 'com.xiangchen.miaoxiaobao',
        [App.APP_PREPARE_01]: 'com.yaobijilai',
    }),
    // API server
    API_SERVER: selectByApp(process.env.API_SERVER, {
        [App.APP_MIAOQIANGUAN]: process.env.API_SERVER_MQG,
        [App.APP_MIAOTEHUI]: process.env.API_SERVER_MTH,
        [App.APP_MIAOFULI]: process.env.API_SERVER_MFL,
        [App.APP_MIAOYOUHUI]: process.env.API_SERVER_MYH,
        [App.APP_MIAOXIAOBAO]: process.env.API_SERVER_MXB,
    }) || '',
    WEB_SITE: (_a = process.env.WEB_SITE) !== null && _a !== void 0 ? _a : '',
    STATIC_DOMAIN: (_b = process.env.STATIC_CDN) !== null && _b !== void 0 ? _b : '',
    // Sentry
    SENTRY_DSN: (_c = process.env.SENTRY_DSN) !== null && _c !== void 0 ? _c : '',
    SENTRY_ENV: (_d = process.env.SENTRY_ENV) !== null && _d !== void 0 ? _d : '',
    // IM
    IM_SOCKET_HOST: (_e = process.env.IM_SOCKET_HOST) !== null && _e !== void 0 ? _e : '',
    // 走路赚钱抵用金版
    GAME_WALK_MONEY_LINK: (_f = process.env.GAME_WALK_MONEY_LINK) !== null && _f !== void 0 ? _f : '',
    // 会员饿了么落地页链接
    ELEME_LINK: (_g = process.env.ELEME_LINK) !== null && _g !== void 0 ? _g : '',
    // 饿了么买菜落地页链接
    ELEME_VEGETABLE_LINK: (_h = process.env.ELEME_VEGETABLE_LINK) !== null && _h !== void 0 ? _h : '',
    // 高德打车链接
    AMAP_DACHE_LINK: (_j = process.env.AMAP_DACHE_LINK) !== null && _j !== void 0 ? _j : '',
    // 京东新人0.1元购活动链接
    JD_NEW_USER_LINK: (_k = process.env.JD_NEW_USER_LINK) !== null && _k !== void 0 ? _k : '',
    // 淘特页pid
    TAOBAO_TEJIA_PID: (_l = process.env.TAOBAO_TEJIA_PID) !== null && _l !== void 0 ? _l : '',
    // 京东CPA虚假进度
    JD_CPA_FAKE_PROGRESS_START_DATE: (_m = process.env.JD_CPA_FAKE_PROGRESS_START_DATE) !== null && _m !== void 0 ? _m : '',
    // 自营商品
    // 哈罗单车品牌ID
    OFFICIAL_ITEM_BRAND_HALUO_SPUID: (_o = process.env.OFFICIAL_ITEM_BRAND_HALUO_SPUID) !== null && _o !== void 0 ? _o : '',
    // 话费充值品牌ID
    OFFICIAL_ITEM_PHONE_CHARGE_SPUID: (_p = process.env.OFFICIAL_ITEM_PHONE_CHARGE_SPUID) !== null && _p !== void 0 ? _p : '',
    // 话费礼包调用成功
    GIFTPACK_RECHARGE_SUCCESS: (_q = process.env.GIFTPACK_RECHARGE_SUCCESS) !== null && _q !== void 0 ? _q : '',
    // 淘宝百川key
    TAOBAO_APP_KEY: selectByApp('25636813', {
        [App.APP_MIAOQIANGUAN]: '25054428',
        [App.APP_MIAOTEHUI]: '33453617',
        [App.APP_MIAOFULI]: '33454619',
        [App.APP_MIAOYOUHUI]: '33443277',
        [App.APP_MIAOXIAOBAO]: '33452869',
    }),
    // 京东开普勒ID
    JD_KEPLER_ID: selectByApp('JDshihuimiao20190715', {
        [App.APP_MIAOQIANGUAN]: '',
        [App.APP_MIAOTEHUI]: '',
        [App.APP_MIAOFULI]: '',
        [App.APP_MIAOYOUHUI]: '',
        [App.APP_MIAOXIAOBAO]: '',
    }),
    // 电费充值到帐时间
    ECHAGE_SHIP_TIME_LIMIT: '5个工作日',
    // 话费慢充到账时间
    RECHARGE_MOBILE_SLOW_TIME_LIMIT: '5个工作日',
    // 话费快充到账时间
    RECHARGE_MOBILE_DIRECT_TIME_LIMIT: '12小时',
    // 话费快充到账时间
    RECHARGE_MOBILE_FAST_TIME_LIMIT: '30分钟',
    // 秒杀
    ACTIVITY_SECKILL_CATEGORY_ID: (_r = process.env.ACTIVITY_SECKILL_CATEGORY_ID) !== null && _r !== void 0 ? _r : '',
    // 生活权益秒杀
    MEMBER_MALL_ACTIVITY_SECKILL_CATEGORY_ID: (_s = process.env.MEMBER_MALL_ACTIVITY_SECKILL_CATEGORY_ID) !== null && _s !== void 0 ? _s : '',
    // 喵粉社区的类目主ID
    SHARE_MAKE_MONEY_CATEGORY_ID: (_t = process.env.SHARE_MAKE_MONEY_CATEGORY_ID) !== null && _t !== void 0 ? _t : '',
    // 京东新人页category_id
    JD_CPA_CATEGORY_ID: (_u = process.env.JD_CPA_CATEGORY_ID) !== null && _u !== void 0 ? _u : '',
    // 淘特页category_id
    TAOBAO_TEJIA_CATEGORY_ID: (_v = process.env.TAOBAO_TEJIA_CATEGORY_ID) !== null && _v !== void 0 ? _v : '',
    // 淘宝特价版
    TAOBAO_SPECIAL_SALE_CATEGORY_ID: (_w = process.env.TAOBAO_SPECIAL_SALE_CATEGORY_ID) !== null && _w !== void 0 ? _w : '',
    // 1元包邮 -> 0元包邮
    ITEMS_FOR_1YUAN_BUY_CATEGORY_ID: (_x = process.env.ITEMS_FOR_1YUAN_BUY_CATEGORY_ID) !== null && _x !== void 0 ? _x : '',
    // 抵用金
    TAOBAO_VOUCHER_CATEGORY_ID: (_y = process.env.TAOBAO_VOUCHER_CATEGORY_ID) !== null && _y !== void 0 ? _y : '',
    // 领取优惠券任务跳转id
    GET_ITEMS_COUPON_TASK_CATEGORY_ID: (_z = process.env.GET_ITEMS_COUPON_TASK_CATEGORY_ID) !== null && _z !== void 0 ? _z : '',
    // 收藏商品任务跳转id
    ADD_FAV_ITEM_TASK_CATEGORY_ID: (_0 = process.env.ADD_FAV_ITEM_TASK_CATEGORY_ID) !== null && _0 !== void 0 ? _0 : '',
    // 喵币兑换淘礼金商品
    MIAO_COIN_EXCHANGE_TAOLIJIN_CATEGORY_ID: (_1 = process.env.MIAO_COIN_EXCHANGE_TAOLIJIN_CATEGORY_ID) !== null && _1 !== void 0 ? _1 : '',
    // 省钱卡饿了么券
    SAVE_MONEY_UNION_CARD_ELM_CATEGORY_ID: (_2 = process.env.SAVE_MONEY_UNION_CARD_ELM_CATEGORY_ID) !== null && _2 !== void 0 ? _2 : '',
    // 省钱卡美团券
    SAVE_MONEY_UNION_CARD_MEITUAN_CATEGORY_ID: (_3 = process.env.SAVE_MONEY_UNION_CARD_MEITUAN_CATEGORY_ID) !== null && _3 !== void 0 ? _3 : '',
    // 站外话费券充值页面
    PARTNER_PHONE_BILL_CATEGORY_ID: (_4 = process.env.PARTNER_PHONE_BILL_CATEGORY_ID) !== null && _4 !== void 0 ? _4 : '',
    // 站内话费类目id
    INNER_SITE_PHONE_BILL_CATEGORY_ID: (_5 = process.env.INNER_SITE_PHONE_BILL_CATEGORY_ID) !== null && _5 !== void 0 ? _5 : '',
    // 电费充值spuID
    ECHARGE_SPU_ID: (_6 = process.env.ECHARGE_SPU_ID) !== null && _6 !== void 0 ? _6 : '',
    // 0元包邮自营商品类目
    OFFICIAL_CATEGORY_ID_IN_ZERO_BUY: (_7 = process.env.OFFICIAL_CATEGORY_ID_IN_ZERO_BUY) !== null && _7 !== void 0 ? _7 : '',
    // 萌推现金红包礼包商品ID
    MT_CASH_GIFT_PACKAGE_ITEM_ID: process.env.MT_CASH_GIFT_PACKAGE_ITEM_ID,
};
