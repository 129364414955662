import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { render } from 'react-dom';
import styles from './index.css';
var HudType;
(function (HudType) {
    HudType[HudType["TEXT"] = 2] = "TEXT";
    HudType[HudType["LOADING"] = 1] = "LOADING";
})(HudType || (HudType = {}));
const HudComponent = (props) => {
    const newProps = props || {};
    return (_jsxs(_Fragment, { children: [newProps.mark && _jsx("div", { className: styles.alphaMark }), newProps.type === HudType.LOADING && (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: styles.hudContainer }, { children: [_jsx("div", { className: styles.hudIcon }), _jsx("span", Object.assign({ className: styles.hudText }, { children: newProps.text || '加载中...' }))] })) })), newProps.type === HudType.TEXT && (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: styles.hudTextContainer }, { children: _jsx("div", { className: styles.hudText, dangerouslySetInnerHTML: {
                            __html: typeof (newProps === null || newProps === void 0 ? void 0 : newProps.text) === 'string' ? newProps === null || newProps === void 0 ? void 0 : newProps.text : '',
                        } }) })) }))] }));
};
HudComponent.displayName = 'HudComponent';
let bodyElement;
let rootElement;
const getRootElement = () => {
    if (!bodyElement) {
        bodyElement = document.querySelector('body');
    }
    if (!rootElement) {
        rootElement = document.createElement('div');
    }
    if (bodyElement && rootElement) {
        bodyElement.appendChild(rootElement);
    }
    return rootElement;
};
let timer = null;
const close = (duration) => {
    const root = getRootElement();
    if (timer) {
        clearTimeout(timer);
    }
    if (duration) {
        timer = setTimeout(() => {
            if (root && root.parentNode) {
                root.parentNode.removeChild(root);
            }
        }, duration);
    }
    else if (root && root.parentNode) {
        root.parentNode.removeChild(root);
    }
};
export const Hud = {
    Loading(props) {
        if (props && props.duration) {
            close(props.duration);
        }
        render(_jsx(HudComponent, Object.assign({}, props, { type: HudType.LOADING })), getRootElement());
        return () => close();
    },
    Text(props) {
        if (props && props.duration) {
            close(props.duration);
        }
        render(_jsx(HudComponent, Object.assign({}, props, { type: HudType.TEXT })), getRootElement());
        return () => close();
    },
    info(message, duration = 1500) {
        return Hud.Text({ text: message, duration });
    },
    LoadingPromise(promise, text = '加载中...', delay = 600) {
        let hudClose;
        const ticker = setTimeout(() => {
            hudClose = this.Loading({ text });
        }, delay);
        const hide = () => {
            clearTimeout(ticker);
            if (hudClose) {
                hudClose();
            }
        };
        return promise
            .then((res) => {
            hide();
            return res;
        })
            .catch((err) => {
            hide();
            throw err;
        });
    },
};
